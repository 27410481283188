import React, { Fragment, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { TagsFilled } from '@ant-design/icons';

import { getColorLabel } from '../../../services/colorService';
import auth from '../../../services/authService';
import './addressHeaderBlock.css';
import creApi from '../../../services/creService';
import { useEffect } from 'react';
import { Button, Modal, Input, Popover } from "antd";

const isMasterClient = !auth.getCurrentUser()?.isSubUser;

const AddressHeaderBlock = (props) => {
  const location = useLocation();
  const params = useParams();
  const [unitEditMode, setUnitEditMode] = useState(false);
  const [units, setUnits] = useState();

  const [address, setAddress] = useState('')
  const [displayAddress, setDisplayAddress] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setUnits(props.data.totalUnitsInBuilding || 0);
  }, [props.data.totalUnitsInBuilding]);

  useEffect(() => {

    setDisplayAddress(props.data.address);
  }, [props.data.address]);

  const updateDisplayAddress = async () => {
    const updateAddress = address || props.data.original_address
    try {
      const result = await creApi.updateDisplayAddress(
        address || props.data.original_address,
        props.data.bin
      );
      if (result.data?.status) {
        toast.success('Display Address Upadated');
        setDisplayAddress(updateAddress)
      } else {
        toast.error(result.data.message);
      }
    } catch (e) {
      toast.error(e.message);

    }


    setIsModalVisible(false)
  }

  const TotalUnitsOnclickHandler = async () => {
    if (unitEditMode && props.data.totalUnitsInBuilding !== units) {
      try {
        const result = await creApi.updateUnitsInBuilding(
          units || 0,
          props.data.bin
        );
        if (result.data?.status) {
          if (!isNaN(result.data?.VPU)) {
            props.setVPU?.(result.data.VPU);
          }
        } else {
          toast.error(result.data.message);
          setUnits(props.data.totalUnitsInBuilding);
        }
      } catch (e) {
        toast.error(e.message);
        return;
      }
    }
    setUnitEditMode(!unitEditMode);
  };
  const totalUnits = units ? units : 'Unknown';

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row head-address">
          <div className="col-12 align-items-center d-flex col-lg-5 align-self-start mt-3">
            <h4>
              <span
                className="btn btn-light btn-xs align-self-center back-btn mr-2 "
                onClick={props.handleGoBack}
                type="button"
              ><i className="fa fa-angle-left"></i></span>
            </h4>
            <div className="">

              <h4>
                <span
                  onClick={
                    props.handleCategoryUrl ? props.handleCategoryUrl : () => {
                    }
                  }
                  style={{ cursor: 'pointer' }}
                >
                  {props.category} {props.category && props.sub ? ' / ' : ''}{' '}
                  {props.sub && (
                    <>
                      {params.category === 'Colors' && getColorLabel(props.sub)}
                      {params.category !== 'Colors' &&
                        props.sub.replace(/_/g, ' ').replace(/__/g, ' ')}
                    </>
                  )}
                </span>
              </h4>
              <h5 className='mb-0'>
                <Link
                  to={`/violations/all/${props.data.bin}`}
                  style={{ color: '#403f72' }}
                >
                  {displayAddress}
                </Link>
                <TagsFilled
                  style={{ fontSize: '22px' }}
                  className='pointer ml-1'
                  onClick={() => { setIsModalVisible(true); setAddress(displayAddress) }}
                />
              </h5>
              {displayAddress !== props.data.original_address && <small>{props.data.original_address}</small>}
              
              {
                props.data.possibleAddresses?.[0] &&
                <Popover content={<div>{props.data.possibleAddresses.map(el => <small key={el}className='d-block'>{el}</small>)}</div>} trigger="click" placement='bottom' >
                  <small style={{ color: '#007bff' }} className='pointer ml-1'>[ Also known as ]</small>
                </Popover>
              }
            </div>
          </div>
          <Modal
            title="Display Address"
            visible={isModalVisible}
            footer={null}
            onCancel={() => setIsModalVisible(false)}

          >
            {props.data.original_address === displayAddress ?
              <div className='mb-2'>Enter Display As: </div>
              :
              <div className='mb-2 pointer' onClick={() => setAddress(props.data.original_address)}>Original address:  {props.data.original_address}</div>
            }
            <Input value={address} onChange={(event) => setAddress(event.target.value)} />
            <Button type="primary" className='mt-2' onClick={updateDisplayAddress}>Save</Button>

          </Modal>
          <div className="col-12 col-lg-7 mt-3 mb-3 head-address-detail">
            <div className="d-flex justify-content-around">
              <div className="col">
                <div className="d-flex">
                  <label className="col-5 col-sm-6 col-form-label pt-0 text-left">
                    BIN#:
                  </label>
                  <div className="col-7 col-sm-6 p-0">
                    <div className="form-control-static">
                      <Link
                        to={`/violations/all/${props.data.bin}`}
                        style={{ color: '#403f72' }}
                      >
                        {props.data.bin}
                      </Link>
                      <a
                        id="open_dob"
                        className="badge badge-light badgeH5"
                        rel="noopener noreferrer"
                        href={
                          'http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?bin=' +
                          props.data.bin
                        }
                        target="_blank"
                      >
                        DOB <i className="fa fa-external-link" />
                      </a>
                      <UncontrolledTooltip placement="right" target="open_dob">
                        Open DOB Page
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <label className="col-5 col-sm-6 col-form-label pt-0 text-left">
                    Block#:
                  </label>
                  <div className="col-7 col-sm-6 p-0">
                    <div className="form-control-static">{props.data.block}</div>
                  </div>
                </div>
                <div className="d-flex">
                  <label className="col-5 col-sm-6 col-form-label pt-0 text-left">
                    Lot#:
                  </label>
                  <div className="col-7 col-sm-6 p-0">
                    <div className="form-control-static">{props.data.lot}</div>
                  </div>
                </div>
                <div className="d-flex">
                  <label className="col-5 col-sm-6 col-form-label pt-0 text-left">
                    Health Area:
                  </label>
                  <div className="col-7 col-sm-6 p-0">
                    <div className="form-control-static">
                      {props.data.hasOwnProperty('additionalInfo')
                        ? props.data.additionalInfo['Health Area']
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex">
                  <label className="col-6 col-sm-7 col-form-label pt-0  text-left">
                    Community Board:
                  </label>
                  <div className="col-6 col-sm-5">
                    <div className="form-control-static">
                      {props.data.hasOwnProperty('additionalInfo')
                        ? props.data.additionalInfo['Community Board']
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <label className="col-6 col-sm-7 col-form-label pt-0 text-left">
                    Buildings on Lot:
                  </label>
                  <div className="col-6 col-sm-5">
                    <div className="form-control-static">
                      {props.data.hasOwnProperty('additionalInfo')
                        ? props.data.additionalInfo['Buildings on Lot']
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <label className="col-6 col-sm-7 col-form-label pt-0 text-left">
                    District:
                  </label>
                  <div className="col-6 col-sm-5">
                    <div className="form-control-static">
                      {props.data.district || '--'}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <label
                    className="col-6 col-sm-7 col-form-label pt-0 text-left label-total-units">
                    Total Units:
                  </label>
                  <div className="col-6 col-sm-5">
                    <div className="d-flex justify-content-between flex-wrap text-nowrap">
                      <div className="form-control-static mr-1">
                        {unitEditMode ? (
                          <input
                            className="unit-input"
                            type="number"
                            value={units}
                            onChange={(e) => {
                              setUnits(e.target.value);
                            }}
                          />
                        ) : (
                          <>
                            {totalUnits}{' '}
                            {isMasterClient && (
                              <span
                                onClick={TotalUnitsOnclickHandler}
                                className="unit-edit"
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </span>
                            )}
                          </>
                        )}
                        {isMasterClient && unitEditMode && (
                          <Button
                            size={"small"}
                            className="btn btn-darkblue py-0 btn-xs px-2 ml-1"
                            onClick={TotalUnitsOnclickHandler}
                          >
                            Save
                          </Button>
                        )}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {props.hasOwnProperty('violationDetailsPage') && (
                <div className="d-flex flex-column align-items-center col-3 ">
                  <div className="text-center btn btn-danger btn-xs link-btb">
                    <Link
                      to={`${location.pathname}?hire-us`}
                    >
                      Hire Us
                    </Link>
                  </div>
                  <div className="text-center btn btn-danger mt-3 btn-xs link-btb">
                    <Link
                      to={`${location.pathname}?need-help`}
                    >
                      Questions?
                    </Link>
                  </div>
                </div>
              )}
            </div>

          </div>

        </div>
      </div>
    </Fragment>
  );
};

export default AddressHeaderBlock;
